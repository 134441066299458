<div class="servico-orcamento">
    <div class="pt-3 text-center">
        <h4>{{ Orcamento.Id ? 'Adicionando Itens' : 'Revise seu Orçamento' | translate}}</h4>
    </div>

    <section class="aereoSection" *ngIf="Orcamento.Aereos[0]">
        <div class='row'>
            <div class='col text-center'>
                <h4 class="title"><i class="fas fa-plane"></i>{{'Aéreo' | translate}}</h4>
                <button class="btn btn-success" (click)="adicionarItem('aereo')">
                    <i class="fas fa-plus"></i>{{'Adicionar' | translate}}
                </button>
            </div>
        </div>
        <div *ngFor="let aereo of Orcamento.Aereos; let j = index" class="container">
            <div class="row" *ngIf="Orcamento.Aereos.length > -1">
                <div class="exclude mb-2">
                    <div class="row">
                        <div class="col text-end">
                            <button class="btn btn-danger" (click)="confirmarDelete(j, Orcamento.Aereos)">
                                <i class="fas fa-trash"></i>{{"Remover" | translate}}
                            </button>
                        </div>
                    </div>
                </div>
                <app-orcamento-aereo-card [index]="j" [aereo]="aereo" [pacote]=false></app-orcamento-aereo-card>
            </div>
        </div>
    </section>

    <section class="hotelSection" *ngIf="Orcamento.Hoteis[0]">
        <div class='row mt-5'>
            <div class='col text-center'>
                <h4 class="title"><i class="fas fa-concierge-bell"></i>{{'Hotel' | translate}}</h4>
                <button class="btn btn-success" (click)="adicionarItem('hotel')">
                    <i class="fas fa-plus"></i>{{'Adicionar' | translate}}
                </button>
            </div>
        </div>
        <div *ngFor="let hotel of Orcamento.Hoteis; let i = index" class="container">
            <div class="row" *ngIf="Orcamento.Hoteis.length > -1">
                <div class="exclude mb-2">
                    <div class="row">
                        <div class="col text-end">
                            <button class="btn btn-danger" (click)="confirmarDelete(i, Orcamento.Hoteis)">
                                <i class="fas fa-trash"></i>{{'Remover' | translate}}
                            </button>
                        </div>
                    </div>
                </div>
                <app-orcamento-hotel-card [index]="i" [hotel]="hotel"
                    [pacotetirapreco]=false></app-orcamento-hotel-card>
            </div>
        </div>
    </section>

    <section class="servicoSection" *ngIf="Orcamento.Servicos[0]">
        <div class='row mt-5'>
            <div class='col text-center'>
                <h4 class="title"><i class="fas fa-route"></i>{{'Serviço' | translate}}</h4>
                <button class="btn btn-success" (click)="adicionarItem('servico')">
                    <i class="fas fa-plus"></i>{{'Adicionar' | translate}}
                </button>
            </div>
        </div>
        <div *ngFor="let servico of Orcamento.Servicos; let k = index" class="container">
            <div class="row" *ngIf="Orcamento.Servicos.length > -1">
                <div class="exclude mb-2">
                    <div class="row">
                        <div class="col text-end">
                            <button class="btn btn-danger" (click)="confirmarDelete(k, Orcamento.Servicos)">
                                <i class="fas fa-trash"></i>{{'Remover' | translate}}
                            </button>
                        </div>
                    </div>
                </div>
                <app-orcamento-servico-card [index]="k" [servico]="servico" [pacote]=false></app-orcamento-servico-card>
            </div>
        </div>
    </section>

    <!-- <section class="pacoteSection" *ngIf="Orcamento.Pacotes[0]">
    <div class='row mt-5'>
      <div class='col text-center'>
        <h4 class="title"><i class="fas fa-star"></i>{{'Pacote' | translate}}</h4>
        <button class="btn btn-success" (click)="adicionarPacote()">
          <i class="fas fa-plus"></i>{{'Adicionar' | translate}}
        </button>
      </div>
    </div>
    <div *ngFor="let pacote of Orcamento.Pacotes; let k = index" class="container">
      <div class="row" *ngIf="Orcamento.Pacotes.length > -1">
        <div class="exclude mb-2">
          <div class="row">
            <div class="col text-end">
              <button class="btn btn-danger" (click)="confirmarDelete(k, Orcamento.Pacotes)">
                <i class="fas fa-trash"></i>{{'Remover' | translate}}
              </button>
            </div>
          </div>
        </div>
        <app-orcamento-pacote-card
          [index]="k"
          [pacote]="pacote"
        ></app-orcamento-pacote-card>
      </div>
    </div>
  </section> -->

    <section class="row" *ngIf="Orcamento.PacoteReservas[0]">
        <div class='col-md-12 mt-5'>
            <div class='col text-center'>
                <h4 class="title"><i class="fas fa-star"></i>{{'Pacote' | translate}}</h4>

            </div>
        </div>
        <div class='d-flex justify-content-center'>
            <div class='container col-md-12' style="margin: 0;">
              <h4 class="col-md-5">{{"Dia a dia" | translate }}</h4>
            </div>
          </div>
          <div class="d-flex justify-content-center">
            <div class="card col-md-8">
                <div class="card-body d-flex justify-content-center">
                    <!-- <h3 class="card-title text-center cor-primaria mb-4">{{pacoteReserva.Nome}}</h3> -->
                    <div class="p-2 col-md-12">
                        <ul class="nav nav-tabs" id="tabInfoServico" role="tablist">
                            <li class="nav-item" role="presentation"
                                *ngFor="let inclui of Orcamento.PacoteReservas[0]?.Pacote?.PacotesInclui; let x = index">
                                <button class="nav-link" [ngClass]="x == 0 ? 'active' : ''" id="tabservico{{x}}"
                                    data-bs-toggle="tab" [attr.data-bs-target]="'#tabservicox' + x" type="button"
                                    style="text-decoration: none !important;" role="tab"
                                    [attr.aria-controls]="'tabservicox' + x"
                                    [attr.aria-selected]="x == 0 ? true : false">{{inclui?.Titulo}}
                                </button>
                            </li>
                        </ul>
                        <div class="tab-content p-2 mt-2" id="tabInfoServicoContent">
                            <div *ngFor="let inclui of Orcamento.PacoteReservas[0]?.Pacote?.PacotesInclui; let x = index"
                                class="tab-pane fade" id="tabservicox{{x}}" [ngClass]="x == 0 ? 'show active' : ''"
                                role="tabpanel" [attr.aria-labelledby]="'tabservico' + x" [innerHTML]="inclui?.Descritivo">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class='d-flex justify-content-center' *ngIf="totalValorListagemServico>0">
            <div class='container col-md-12'  style="margin: 25px 0px 0px 0px;">
              <h4 class="col-md-5 mt-3" style="margin: 0;">{{"Serviço(s) Incluído(s)" | translate }}</h4>
            </div>
          </div>
        <div *ngFor="let servico of Orcamento.PacoteReservas[0].Servicos; let k = index" class="container">
            <div class="d-flex justify-content-center" *ngIf="Orcamento.PacoteReservas[0].Servicos.length > -1">
                <app-orcamento-servico-card [index]="k" [servico]="servico" [pacote]="true"
                    class="col-md-8"></app-orcamento-servico-card>
            </div>
        </div>
        <div class='d-flex justify-content-center'>
            <div class='container col-md-12' style="margin: 25px 0px 0px 0px;">
              <h4 class="col-md-5">{{"Hotel Selecionado" | translate }}</h4>
            </div>
          </div>
        <div *ngFor="let hotel of Orcamento.PacoteReservas[0].Hoteis; let k = index" class="container">
            <div class="d-flex justify-content-center" *ngIf="Orcamento.PacoteReservas[0].Hoteis.length > -1">
                <app-orcamento-hotel-card [index]="k" [hotel]="hotel" [Pacote]="true" [pacotetirapreco]="false" [ValorServico]="totalValorListagemServico"
                    class="col-md-8"></app-orcamento-hotel-card>
            </div>
        </div>



        

    </section>

    <section class="carroSection" *ngIf="Orcamento.Carros[0]">
        <div class='row mt-5'>
            <div class='col text-center'>
                <h4 class="title"><i class="fas fa-car"></i>{{'Carro' | translate}}</h4>
                <button class="btn btn-success" (click)="adicionarItem('carro')">
                    <i class="fas fa-plus"></i>{{'Adicionar' | translate}}
                </button>
            </div>
        </div>
        <div *ngFor="let carro of Orcamento.Carros; let l = index" class="container">
            <div class="row" *ngIf="Orcamento.Carros.length > -1">
                <div class="exclude mb-2">
                    <div class="row">
                        <!-- <div class="col text-start">
                            <h4>Carro - {{l + 1}}</h4>
                        </div> -->
                        <div class="col text-end">
                            <button class="btn btn-danger" (click)="confirmarDelete(l, Orcamento.Carros)">
                                <i class="fas fa-trash"></i>{{'Remover' | translate}}
                            </button>
                        </div>
                    </div>
                </div>
                <app-orcamento-carro-card [index]="l" [carro]="carro" [pacote]=false></app-orcamento-carro-card>
            </div>
            <hr />
        </div>
    </section>



    <div class="generate">
        <button class="btn btn-success" (click)="salvarOrcamento(); clicked = !clicked" [disabled]="clicked">
            {{ Orcamento.Id ? 'Adicionar Itens' : 'Gerar Orçamento' | translate}}
        </button>
    </div>
</div>


<ng-template #contentPacoteDetalhes let-modal>
    <app-hotel-detalhes [hotel]="objModal" [pacote]="true"></app-hotel-detalhes>
</ng-template>